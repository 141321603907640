<template>
  <v-container>
    <v-card>
      <v-row justify="center" id="main_row">
        <v-col align="center" align-self="center" lg="4">
          <v-hover v-slot:default="{ hover }">
            <v-img :src="currentFeature.image" max-height="400" max-width="400" contain style="cursor: pointer"
              @click="$router.push({ name: 'Features' })">
              <v-fade-transition>
                <v-overlay v-if="hover" :value="hover" absolute>
                  <div class="text-center">Read More...</div>
                </v-overlay>
              </v-fade-transition>
            </v-img>
          </v-hover>
        </v-col>
        <v-col align="start" align-self="center">
          <v-card-title style="word-break: keep-all" :style="
            $vuetify.breakpoint.smAndDown
              ? 'font-size:1.1em;font-weight:bold'
              : 'font-size:1.5em;font-weight:bold'
          ">This weekend's feature is
            {{ currentFeature.artist ? currentFeature.artist : currentFeature.title }}
          </v-card-title>

          <v-card-text :style="$vuetify.breakpoint.smAndDown ? 'font-size:1em' : 'font-size:1.3em'"
            v-html="currentFeature.intro_text">
          </v-card-text>
          <router-link :to="{name: 'Features'}" ><u>Read More</u></router-link>
          <v-card-text v-if="currentFeature.artist">
            We hope you’ll take time to search through our archives at find your favorite {{ currentFeature.artist }}
            song. Please take time to request it so we can all be blessed by it. Who knows, you just might discover a
            new favorite!
          </v-card-text>
          <v-card-actions v-if="currentFeature.artist">
            <v-btn :to="currentFeature.requesturl" color="primary">Request {{ currentFeature.artist }}</v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "HomePageFeature",
  data: () => ({}),
  computed: {
    currentFeature() {
      return this.$store.state.featureObjects.features;
    },
  },
};
</script>

<style scoped lang="scss">
#main_row {
  background-image: url("../../assets/blue.jpg");
  background-size: 100% 100%;
}
</style>
